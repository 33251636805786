import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _543d0c1c = () => interopDefault(import('../pages/article/index.vue' /* webpackChunkName: "pages/article/index" */))
const _26058e5c = () => interopDefault(import('../pages/config/index.vue' /* webpackChunkName: "pages/config/index" */))
const _709bd10e = () => interopDefault(import('../pages/confirm.vue' /* webpackChunkName: "pages/confirm" */))
const _1c99d00a = () => interopDefault(import('../pages/products.vue' /* webpackChunkName: "pages/products" */))
const _c7222176 = () => interopDefault(import('../pages/shipping.vue' /* webpackChunkName: "pages/shipping" */))
const _231868d0 = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _6792173c = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _7a029811 = () => interopDefault(import('../pages/template.vue' /* webpackChunkName: "pages/template" */))
const _8f23294c = () => interopDefault(import('../pages/config/delivery.vue' /* webpackChunkName: "pages/config/delivery" */))
const _4b3908f4 = () => interopDefault(import('../pages/config/guide.vue' /* webpackChunkName: "pages/config/guide" */))
const _829136c4 = () => interopDefault(import('../pages/config/history.vue' /* webpackChunkName: "pages/config/history" */))
const _88c82cbe = () => interopDefault(import('../pages/config/mypage.vue' /* webpackChunkName: "pages/config/mypage" */))
const _b894d306 = () => interopDefault(import('../pages/config/privacy-policy.vue' /* webpackChunkName: "pages/config/privacy-policy" */))
const _21fe08a2 = () => interopDefault(import('../pages/config/regist-shipping.vue' /* webpackChunkName: "pages/config/regist-shipping" */))
const _1b01f726 = () => interopDefault(import('../pages/config/specified-commercial.vue' /* webpackChunkName: "pages/config/specified-commercial" */))
const _559c68e0 = () => interopDefault(import('../pages/config/template.vue' /* webpackChunkName: "pages/config/template" */))
const _6385abf8 = () => interopDefault(import('../pages/config/test.vue' /* webpackChunkName: "pages/config/test" */))
const _e61aed7a = () => interopDefault(import('../pages/article/_article_id.vue' /* webpackChunkName: "pages/article/_article_id" */))
const _3c4a370c = () => interopDefault(import('../pages/complete/_order_id.vue' /* webpackChunkName: "pages/complete/_order_id" */))
const _da07d66a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/article",
    component: _543d0c1c,
    name: "article"
  }, {
    path: "/config",
    component: _26058e5c,
    name: "config"
  }, {
    path: "/confirm",
    component: _709bd10e,
    name: "confirm"
  }, {
    path: "/products",
    component: _1c99d00a,
    name: "products"
  }, {
    path: "/shipping",
    component: _c7222176,
    name: "shipping"
  }, {
    path: "/shop",
    component: _231868d0,
    name: "shop"
  }, {
    path: "/success",
    component: _6792173c,
    name: "success"
  }, {
    path: "/template",
    component: _7a029811,
    name: "template"
  }, {
    path: "/config/delivery",
    component: _8f23294c,
    name: "config-delivery"
  }, {
    path: "/config/guide",
    component: _4b3908f4,
    name: "config-guide"
  }, {
    path: "/config/history",
    component: _829136c4,
    name: "config-history"
  }, {
    path: "/config/mypage",
    component: _88c82cbe,
    name: "config-mypage"
  }, {
    path: "/config/privacy-policy",
    component: _b894d306,
    name: "config-privacy-policy"
  }, {
    path: "/config/regist-shipping",
    component: _21fe08a2,
    name: "config-regist-shipping"
  }, {
    path: "/config/specified-commercial",
    component: _1b01f726,
    name: "config-specified-commercial"
  }, {
    path: "/config/template",
    component: _559c68e0,
    name: "config-template"
  }, {
    path: "/config/test",
    component: _6385abf8,
    name: "config-test"
  }, {
    path: "/article/:article_id",
    component: _e61aed7a,
    name: "article-article_id"
  }, {
    path: "/complete/:order_id?",
    component: _3c4a370c,
    name: "complete-order_id"
  }, {
    path: "/",
    component: _da07d66a,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
